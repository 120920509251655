import { useNotification } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useUpdate } from "hooks/fetchData/useUpdate";
import { IAdapter, IAdapterDevices, IAdapterForm } from "interfaces";
import { IDialogProps } from "interfaces/IDialogProps";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import AdaptersForm from "./AdaptersForm";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { useState } from "react";

const AdaptersEdit = ({ open, data: id, onClose, onSuccess }: IDialogProps<string>) => {
	const { t } = useTranslation();
	const { addNotification } = useNotification();
	const editFormId = "edit-adapter-form";

	// colocar o botão guardar a disabled
  const [formIsValid, setFormIsValid] = useState(false);
  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

	//pedido para buscar info do adapter
	const { data, loading: loadingView } = useGetAll<IAdapterDevices>(
		ServiceApiUrl.getAdapterDevicesURL(id || "")
	);

	//pedido para editar adapter
	const {
		loading,
		refetch: updateAdapter,
	} = useUpdate<IAdapter>(ServiceApiUrl.adapterURL, data?.id);

	// sucesso
	const handlerSuccess = () => {
		onClose && onClose(); //fecha modal
		addNotification({
			message: t("adapters.adapterEdited"),
			severity: "success",
		});
		onSuccess && onSuccess();
	};

	const formSubmitHandler = async (payLoad: IAdapterForm) => {
		// console.log(payLoad);
		try {
			await updateAdapter({ data: payLoad });
			await handlerSuccess();
		} catch (error) { }
	};

	let adapterEdit = <NoData />;
	if (loadingView) {
		adapterEdit = <Loading />;
	}
	if (data) {
		adapterEdit = <AdaptersForm formId={editFormId} data={data} onFormSubmit={formSubmitHandler} setFormIsValidStatus={getFormIsValidStatus} />;
	}
	return (
		<Dialog maxWidth="md" fullWidth open={open}>
			<DialogTitle>
				<Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
					{t("adapters.editAdapter")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				{adapterEdit}
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={onClose}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!formIsValid}
					type={"submit"}
					form={editFormId}
				>
					{t("common.edit")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default AdaptersEdit;
