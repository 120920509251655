/* eslint-disable no-template-curly-in-string */
import { ArrayLocale, BooleanLocale, DateLocale, LocaleObject, MixedLocale, NumberLocale, ObjectLocale, StringLocale } from "../../interfaces";
import printValue from '../../utils/printValue';

export const mixed: Required<MixedLocale> = {
    default: 'Inválido',
    defined: 'Não deve ser indefinido',
    required: 'Obrigatório',
    oneOf: 'Deve ter um dos seguintes valores: ${values}',    
    notNull: 'O campo não deve ser null',
    notOneOf: 'Não deve ter nenhum dos seguintes valores: ${values}',
    notType: ({
        type, value, originalValue,
    }: any) => {
        const isCast = originalValue != null && originalValue !== value;
        let msg = `${`Deve ser do tipo \`${type}\`, `
            + `mas o valor final é: \`${printValue(value, true)}\``}${isCast
                ? ` (cast do valor \`${printValue(originalValue, true)}\`)`
                : '.'}`;

        if (value === null) {
            msg += '\nSe a intenção era usar "null" como um valor em branco marque o esquema como `.nullable()`';
        }
        
        return msg;
    }
};

export const string: Required<StringLocale> = {
  length: ({ length }: any) => `Deve ter exatamente ${length} ${length === 1 ? 'caracter' : 'caracteres'}`,
  min: ({ min }: any) => `Deve ter pelo menos ${min} ${min === 1 ? 'caracter' : 'caracteres'}`,
  max: ({ max }: any) => `Deve ter no máximo ${max} ${max === 1 ? 'caracter' : 'caracteres'}`,
  matches: 'Deve corresponder ao padrão: "${regex}"',
  email: 'Deve ser um e-mail válido',
  url: 'Deve ser uma URL válida',
  uuid: "Deve ser um UUID válido",
  trim: 'Não deve conter espaços adicionais no início nem no fim',
  lowercase: 'Deve estar em letras minúsculas',
  uppercase: 'Deve estar em letras maiúsculas',
};

export const number: Required<NumberLocale> = {
  min: 'Deve ser maior ou igual a ${min}',
  max: 'Deve ser menor ou igual a ${max}',
  lessThan: 'Deve ser menor que ${less}',
  moreThan: 'Deve ser maior que ${more}',
  // notEqual: 'Não deve ser igual a ${notEqual}',
  positive: 'Deve ser um número positivo',
  negative: 'Deve ser um número negativo',
  integer: 'Deve ser um número inteiro'
};

export const date: Required<DateLocale> = {
  min: 'Deve ser posterior a ${min}',
  max: 'Deve ser anterior a ${max}',
};

export let boolean: BooleanLocale = {
    isValue: "Deve ser ${value}",
  };

export const object: Required<ObjectLocale> = {
  noUnknown: 'Existem chaves desconhecidas: ${unknown}',
};

export const array: Required<ArrayLocale> = {
  min: ({ min } : any) => `Deve ter pelo menos ${min} ${min === 1 ? 'item': 'itens'}`,
  max: ({ max } : any) => `Deve ter no máximo ${max} ${max === 1 ? 'item': 'itens'}`,
  length: "Tem que ter ${length} itens",
};

export default Object.assign(Object.create(null), {
    mixed,
    string,
    number,
    date,
    object,
    array,
    boolean,
  }) as LocaleObject;