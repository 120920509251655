import axios from "axios";
import { configure } from "axios-hooks";

import { IAuth } from "interfaces";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { AUTH_KEY, getLocalStorage } from "utils";

const defaultOptions = {
  baseURL: ServiceApiUrl.baseApiUrl,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "*",
    // "Access-Control-Allow-Headers": "Content-Type",
    "Access-Control-Allow-Headers": "*",
  },
  withCredencials: false,
};

// console.log('axios:', defaultOptions);

// Create instance
export const api = axios.create(defaultOptions);

const cache = false; //new LRUCache({ max: 10 });
configure({ axios: api, cache });

// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     // Do something with response error
//     // if (error.response) {
//     //   // The request was made and the server responded with a status code
//     //   // that falls out of the range of 2xx
//     //   // console.log(error.response.data);
//     //   // console.log(error.response.status);
//     //   // console.log(error.response.headers);
//     //   const config = error.config;
//     //   if (error.response.status === 401 && !config._retry) {
//     //     config._retry = true;

//     //     // clear localStorage auth
//     //     removeItem(AUTH_KEY);

//     //     return axios(config);
//     //   }
//     // } else if (error.request) {
//     //   // The request was made but no response was received
//     //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//     //   // http.ClientRequest in node.js
//     //   console.log("error.request: ", error.request);
//     // }
//     // else {
//     //   // Something happened in setting up the request that triggered an Error
//     //   // console.log("Error: ", error);
//     // }
//     // console.log(error.config);
//     // console.log("Error ", JSON.stringify(error, null, 2));

//     return Promise.reject(error);
//   }
// );
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Do something with response error
    if (error.response) {
      const status: number = error.response.status; // API Request Status Code
      const errorCode: number = error.response.data[0].error_code; // BE Error Code - see BE Docs
      if (status === 401) {
        if (errorCode === 10006) {
					window.location.replace(RoutePaths.UNAUTHORIZED);
        }
        //Se for 10013, deve ser redireccionado para o login "Auth Token Expired"
        //Se for 10014, deve ser redireccionado para o login "Missing Token"
        if (errorCode === 10013 || errorCode === 10014) {
          // clear data of store
          localStorage.clear();
          // navigate for login page
          window.location.replace(RoutePaths.LOGIN);
        }
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("error.request: ", error.request);
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    //const auth = {auth_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiU3VwZXJVc2VyIiwiYWRhcHRlcl9jb25maWd1cmF0b3JzX2lkIjpbXX0.V9DquiGTJ5FahKK7dI9cY38e2iRk24A_WqnlW0jrZ5Y'};

    const auth = getLocalStorage<IAuth | null>(AUTH_KEY);
    const token = auth ? auth.auth_token : null;
    if (token) {
			config.headers.set("Authorization", "Token " + token)
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
