import React, { useEffect } from "react";
import { RequiredField, InputText } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { ICategory } from "interfaces";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { formDefaultValues } from "./formDefaultValues";
import { getCategoryFormSchema } from "./validations";
import { RequiredFields } from "components/Utils/RequiredField";

const CategoriesForm = (props: {
  formId: string;
  data?: ICategory;
  onFormSubmit: (payLoad: ICategory) => void;
	setFormIsValidStatus?: (status: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { formId, data, onFormSubmit, setFormIsValidStatus } = props;

  const defaultValues = formDefaultValues(data);

  const methodsForm = useForm<ICategory>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(getCategoryFormSchema()),
  });

  const { control, handleSubmit } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<ICategory> = (payLoad: ICategory) => {
    onFormSubmit(payLoad);
  };

	useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [methodsForm.formState.isValid, setFormIsValidStatus]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
							<RequiredField title={t("categories.form.nameLabel")} />
              <InputText
                control={control}
                autoFocus={true}
                name="name"
                placeholder={t("categories.form.namePlaceholder")}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
			<RequiredFields />
    </>
  );
};

export default CategoriesForm;
