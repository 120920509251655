import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { ChipStatus } from "components/Utils/ChipStatus";
import { IAdapter } from "interfaces";
import { formatDate, statusColor } from "utils";

const AdapterTableRow = (props: {
  row: IAdapter;
  onDelete?: (row: IAdapter) => void;
  onEdit?: (row: IAdapter) => void;
  onRowClick: (row: IAdapter) => void;
}) => {
  const { row, onDelete, onEdit, onRowClick } = props;
  const { t } = useTranslation();

  return (
    <>
      <TableRow className="pointer" onClick={(e): void => {
          e.stopPropagation();
          onRowClick(row);
        }}>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <ChipStatus
            label={row.is_active ? t("common.active") : t("common.inactive")}
            color={
              row.is_active ? statusColor("active") : statusColor("non-active")
            }
          />
        </TableCell>
        <TableCell>{row.domain}</TableCell>
        <TableCell>
          {row.partner.name}
        </TableCell>
        <TableCell>
          {formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>

        {(onEdit || onDelete) && (
					<TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
						<IconButton
							title={t("common.edit")}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								e.stopPropagation();
								onEdit && onEdit(row);
							}}
						>
							<EditIcon />
						</IconButton>

						<IconButton
							title={t("common.delete")}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								e.stopPropagation();
								onDelete && onDelete(row);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</TableCell>)}
      </TableRow>
    </>
  );
};

export default AdapterTableRow;
