import { object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 32,
    gatewayUrl: 100,
    gatewayUser: 100,
    gatewayPassword: 100,
    domainDevo: 32,
    partnerDevo: 32,
    domain: 32,
  },
  min: {
    name: 1,
    domainDevo: 1,
    partnerDevo: 1,
    domain: 1,
  },
};

const pattern = /^([A-Za-z])+/;

export const getAdapterFormSchema = () =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),
    domain: string()
      .min(InputsLengths.min.domain)
      .max(InputsLengths.max.domain)
			.matches(pattern)
      .required(),
    partner: string().required(),
    tenant: string().required(),

    description: string(),
    gateway_url: string().max(InputsLengths.max.gatewayUrl),
    gateway_user: string().max(InputsLengths.max.gatewayUser),
    gateway_password: string().max(InputsLengths.max.gatewayPassword),
    domain_devo: string()
      .min(InputsLengths.min.domainDevo)
      .max(InputsLengths.max.domainDevo)
			.matches(pattern),

    partner_devo: string()
      .min(InputsLengths.min.partnerDevo)
      .max(InputsLengths.max.partnerDevo)
			.matches(pattern),
  });
