import { TableHeadCell, TableHeadSort } from "@alb/live-lib";
import { Table, TableBody, TableContainer } from "@mui/material";

import { useTranslation } from "react-i18next";

import { ILogs } from "interfaces";
import { arrayIsEmpty, IResultTable, CustomTablePagination } from "utils";

import LogTableRow from "./LogsTableRow";

export const LogsTable = (props: IResultTable) => {
  const { t } = useTranslation();

  const {
    data: logs,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    page,
    setPage,
    loading,
  } = props;

  const tableHeads: TableHeadCell[] = [
    {
      id: "type",
      label: t("logs.list.type"),
    },
    {
      id: "username",
      label: t("logs.list.user"),
    },
    {
      id: "request",
      label: t("logs.list.requestUrl"),
    },
    {
      id: "log_date",
      label: t("common.date"),
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage);
  };

  let row;
  if (!arrayIsEmpty(logs.data)) {
    row = logs.data.map((log: ILogs, index: number) => (
      <LogTableRow
        key={`${log.type}_${index}`}
        row={log}
      />
    ));
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeadSort
            headCells={tableHeads}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            loading={loading}
          />
          <TableBody>{row}</TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        count={logs.totalCount}
        page={page}
				totalPages={logs.totalPages}
        onPageChange={handleChangePage}
      />
    </>
  );
};
