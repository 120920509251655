import { PageLayout } from "@alb/live-lib";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const RequireAuth = () => {
  const auth = useAuth();
  const location = useLocation();

  const state = { from: location };
  
  if (!auth.isLoggedIn) {
    return <Navigate to="/login" state={state} replace />
  }

  return <PageLayout children={<Outlet />} />
};

export default RequireAuth;
