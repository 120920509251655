import { IconButton, TableCell, TableRow } from "@mui/material";
import { ChipStatus } from "components/Utils/ChipStatus";
import { IAccount } from "interfaces";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate, statusColor } from "utils";
import DeleteIcon from "@mui/icons-material/DeleteRounded";

const AccountTableRow = (props: {
  row: IAccount;
  onDelete: (row: IAccount) => void;
  onRowClick: (row: IAccount) => void;
}) => {
  const { row, onDelete, onRowClick } = props;
  const { t } = useTranslation();

  return (
    <>
      <TableRow className="pointer" onClick={(e): void => {
          e.stopPropagation();
          onRowClick(row);
        }}>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <ChipStatus
            label={row.is_active ? t("common.active") : t("common.inactive")}
            color={
              row.is_active ? statusColor("active") : statusColor("non-active")
            }
          />
        </TableCell>
        <TableCell>
          {formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
        <TableCell>
          {formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>

        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          <IconButton
            title={t("common.delete")}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              onDelete(row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AccountTableRow;
