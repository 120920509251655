import { Button, CssBaseline, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ReportIcon from "@mui/icons-material/Report";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ErrorHandler = (props: any) => {
  // console.log('props', props);

  const { error } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goHomeHandler = () => {
    navigate("/", { replace: true });
  };

  return (
    <>
      <CssBaseline />
      <Grid container sx={{ textAlign: "center" }}>
        <Grid item xs={true} mt={12}>
          <ReportIcon color="error" sx={{ fontSize: 80 }} />

          <Typography mt={2} variant="h3">
            {t("errors.somethingWentWrong")}
          </Typography>

          <Typography mb={2} mt={6} component={"span"} variant="h5">
            {error.name}
          </Typography>

          <Grid container alignItems="center" rowSpacing={2}>            
            <Grid mb={0.5} item xs>
              {error.message}
            </Grid>
          </Grid>
          
          <Box mt={5}>
            <Button
              onClick={goHomeHandler}
              size="large"
              variant="contained"
            >
              {t("errors.goToHome")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ErrorHandler;
