import { IAdapter } from "interfaces";

export const formDefaultValues = (data?: IAdapter) => {
  
  const defaultValues = {
    name: data?.name || "",
    description: data?.description || "",
    gateway_user: data?.gateway_user || "",
    gateway_url: data?.gateway_url || "",
    gateway_password: data?.gateway_password || "",
    is_active: (data ? data.is_active : false), 
    protected_ingestion: (data ? data.protected_ingestion : false),
    domain_devo: data?.domain_devo || "",
    domain: data?.domain || "",
    partner_devo: data?.partner_devo || "",
    partner: data?.partner.id || "",
    tenant: data?.tenant.id || "",
  };
  return defaultValues;
};
