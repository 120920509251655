/* eslint-disable no-template-curly-in-string */
import {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  LocaleObject,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
} from "../../interfaces";
import printValue from "../../utils/printValue";

export let mixed: Required<MixedLocale> = {
  default: "Is invalid",
  required: "Is required",
  defined: "Must be defined",
  notNull: "Cannot be null",
  oneOf: "Must be one of the following values: ${values}",
  notOneOf: "Must not be one of the following values: ${values}",
  notType: ({ type, value, originalValue }) => {
    const castMsg =
      originalValue != null && originalValue !== value
        ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
        : ".";

    return type !== "mixed"
      ? `Must be a \`${type}\` type, ` +
          `but the final value was: \`${printValue(value, true)}\`` +
          castMsg
      : `Must match the configured type. ` +
          `The validated value was: \`${printValue(value, true)}\`` +
          castMsg;
  }
};

export let string: Required<StringLocale> = {
  length: "Must be exactly ${length} characters",
  min: "Must be at least ${min} characters",
  max: "Must be at most ${max} characters",
  matches: 'Must match the following: "${regex}"',
  email: "Must be a valid email",
  url: "Must be a valid URL",
  uuid: "Must be a valid UUID",
  trim: "Must be a trimmed string",
  lowercase: "Must be a lowercase string",
  uppercase: "Must be a upper case string",
};

export let number: Required<NumberLocale> = {
  min: "Must be greater than or equal to ${min}",
  max: "Must be less than or equal to ${max}",
  lessThan: "Must be less than ${less}",
  moreThan: "Must be greater than ${more}",
  positive: "Must be a positive number",
  negative: "Must be a negative number",
  integer: "Must be an integer"
};

export let date: Required<DateLocale> = {
  min: "Must be later than ${min}",
  max: "Must be at earlier than ${max}",
};

export let boolean: BooleanLocale = {
  isValue: "Must be ${value}",
};

export let object: Required<ObjectLocale> = {
  noUnknown: "Has unspecified keys: ${unknown}",
};

export let array: Required<ArrayLocale> = {
  min: "Must have at least ${min} items",
  max: "Must have less than or equal to ${max} items",
  length: "Must have ${length} items",
};

export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
}) as LocaleObject;
