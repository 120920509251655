import { TableCell } from "@mui/material";
import { styled} from "@mui/material/styles";
import { TabList } from '@mui/lab';

export const TableCellTruncate = styled(TableCell)<any>(({ maxwidth }) => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	maxWidth: (maxwidth ? `${maxwidth}px` : "300px"),
}));

const indicatorSize = '3px';
export const ContainedTabs = styled(TabList)(({ theme }) => ({
  borderBottom: `${indicatorSize} solid rgba(0, 0, 0, 0.08);`,
  '& .MuiButtonBase-root.MuiTab-root': {
    width: '200px',
    textTransform: 'none',
    marginRight: '4px',
    fontSize: '17px',
    '&.Mui-selected': {
      fontWeight: '700',
      color: theme.palette.text.primary,
    },
    '&:last-of-type': {
      marginRight: '0px',
    },
  },
	'& .MuiTabs-indicator': {
    height: indicatorSize,
  },
}))
