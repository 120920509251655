import { TableHeadOrder } from "@alb/live-lib";
import { useEffect, useState } from "react";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IAdapter, IApiResponse } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { AdaptersTable } from "./AdaptersTable";
import { formatOrderBy, arrayIsEmpty, ROWS_PER_PAGE } from "utils";

const Adapters = (props: { refetch: boolean }) => {
  const { refetch } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: adapters,
    loading,
    refetch: refetchAdapters
  } = useGetAll<IApiResponse<IAdapter>>(ServiceApiUrl.adapterURL, {
    page: page,
    items: ROWS_PER_PAGE,
    order_by: formatOrderBy(order, orderBy),
  });

  useEffect(() => {
    if (refetch) refetchAdapters();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  let adaptersList = <NoData />;

  if (loading) {
    adaptersList = <Loading />;
  }

  if (adapters && !arrayIsEmpty(adapters.data)) {
    adaptersList = (
      <AdaptersTable
        data={adapters}
        orderBy={orderBy}
        order={order}
        loading={loading}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        refetch={refetchAdapters}
      />
    );
  }

  return <>{adaptersList}</>;
};

export default Adapters;
