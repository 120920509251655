import { IStatusColor, StatusColorOptions, ILogType } from "interfaces";

function objectIsEmpty(obj: Object | null | undefined): boolean {
  const length = Object.keys(obj || {}).length;
  return length === 0;
}
function arrayIsEmpty<T>(array: T | null | undefined): boolean {
  return !array || !Array.isArray(array) || !array.length;
}
function stringIsEmpty(string: string | null | undefined): boolean {
  const length = string?.length;
  return length === 0;
}

function valueEmptyTo(value?: string | number, to = "--") {

  const _value = (value) ? value : to;
  return _value
}

function statusColor(status_value: string) {
  const status: IStatusColor = {
    invited: "warning",
    "non-active": "error",
    active: "success",
    deleted: "error",
  };
  return status[status_value as keyof IStatusColor] as StatusColorOptions;
}

function typeColor(type_value: string) {
	const type: ILogType = {
		ERROR: "error",
		INFO: "success"
	}

	return type[type_value as keyof ILogType] as StatusColorOptions;
}

export { objectIsEmpty, arrayIsEmpty, stringIsEmpty, valueEmptyTo, statusColor, typeColor };
