import AdaptersView from "components/Adapters/AdaptersView";

function AdaptersViewPage() {
  return (
    <>
      <AdaptersView />
    </>
  );
}

export default AdaptersViewPage;
