import { LayoutContext } from "@alb/live-lib";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Logs from "components/Logs/Logs";

const LogsPage = () => {
	const { t } = useTranslation();
	let params = useParams();
	const { addHeader } = useContext(LayoutContext);

	const appName = t(`sidebar.menu.${params.id}`) || '';
	const header = {
		title: t("pages.logs.headerText", { "app_name": appName })
	};

	useEffect(() => {
		addHeader(header);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	return (
		<>
			{params.id && <Logs id={params.id} />}
		</>
	);
}

export default LogsPage
