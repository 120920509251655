const _baseApiUrl = process.env.REACT_APP_API_BASE_URL || "";

export const ServiceApiUrl = {
  baseApiUrl: _baseApiUrl,

  loginURL: "login",

  adapterURL: "adapter-configurators",
  accountURL: "accounts",
  tenantURL: "tenants",
  partnerURL: "partners",
  deviceURL: "devices",
  streamURL: "streams",
  categoryURL: "category",

  statisticURL: "statistics/global",

	getLogsURL: (id:string) => {
		return `logs/live-${id}`
	},

  getAdapterDevicesURL: (id: string) => {
    return `/adapter-configurators/${id}/devices`;
  },

};
