import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoData = () => {
  const { t } = useTranslation();
  return (
    <Typography mt={2} variant={"body1"}>
      {t("common.noData")}
    </Typography>
  );
};

export default NoData;
