import React from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import GlobalValues from "./GlobalValues";

import { Card, CardContent, CardHeader } from "@mui/material";

const Summary = () => {
  return (
    <>
      <GlobalValues />
      
      {/* FOR DEMO */}
      <Grid container spacing={2} mt={3} xs={12} >
        <Grid xs={12} sm={6}>
          <Card sx={{height:"500px"}}>
            <CardHeader title="Painel" />
            <CardContent>TODO...</CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6}>
          <Card sx={{height:"500px"}}>
            <CardHeader title="Histórico" />
            <CardContent>TODO...</CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Summary;
