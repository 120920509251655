import AccountsView from "components/Accounts/AccountsView";

function AccountsViewPage() {
  return (
    <>
      <AccountsView />
    </>
  );
}

export default AccountsViewPage;
