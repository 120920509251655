import { object, string } from "yup";

export const InputsLengths = {
  max: {
    email: 254,
  },
  min: {
    password: 5,
  },
};

export const getLoginFormSchema = () =>
  object({
    email: string().max(InputsLengths.max.email).email().required(),
    password: string().required(),
  });

export const getRecoverFormSchema = () =>
  object({
    email: string().max(InputsLengths.max.email).email().required(),
  });
