import { useNotification } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ICategory } from "interfaces";
import { IDialogProps } from "interfaces/IDialogProps";
import { ServiceApiUrl } from "services/ServiceApiUrl";

import { useUpdate } from "hooks/fetchData/useUpdate";
import CategoriesForm from "./CategoryForm";
import { useState } from "react";

const CategoriesEdit = ({ open, data, onClose, onSuccess }: IDialogProps) => {
	const { t } = useTranslation();
	const { addNotification } = useNotification();
	const editFormId = "edit-stream-form";

	// colocar o botão guardar a disabled
	const [formIsValid, setFormIsValid] = useState(false);
	const getFormIsValidStatus = (status: boolean) => {
		setFormIsValid(status);
	};

	//pedido para editar stream
	const {
		loading,
		refetch: updateCategory,
	} = useUpdate<ICategory>(ServiceApiUrl.categoryURL, data?.id);

	// sucesso
	const handlerSuccess = () => {
		onClose && onClose(); //fecha modal
		addNotification({
			message: t("categories.categoryEdited"),
			severity: "success",
		});
		onSuccess && onSuccess();
	};

	const formSubmitHandler = async (payLoad: ICategory) => {
		// console.log(payLoad);
		try {
			await updateCategory({ data: payLoad });
			await handlerSuccess();
		} catch (error) { }
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open}>
			<DialogTitle>
				<Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
					{t("categories.editCategory")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<CategoriesForm formId={editFormId} data={data} onFormSubmit={formSubmitHandler} setFormIsValidStatus={getFormIsValidStatus} />
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={onClose}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!formIsValid}
					type={"submit"}
					form={editFormId}
				>
					{t("common.edit")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default CategoriesEdit;
