import { Chip, Grid } from "@mui/material";
import React from "react";

import { StatusColorOptions } from "interfaces";
interface IChipStatus  {
    label: string;
    color: string;
}

export const ChipStatus = ({label, color}: IChipStatus) => {

  return (
    <Grid display={"flex"} alignItems={"center"} gap={1}>
      <Chip
        size="small"
        label={label}
        variant="filled"
        color={color as StatusColorOptions}
      />
    </Grid>
  );
};
