export const formDefaultValues = () => {
  
  const defaultValues = {
    name: "",
    description: "",
    is_active: false, 
    admin: false,
    adapter_configurators: [],
  };
  return defaultValues;
};
