import { IStream } from "interfaces";

export const formDefaultValues = (data?: IStream) => {
  const defaultValues = {
    name: data?.name || "",
    description: data?.description || "",
    unit: data?.unit || "",

    category: data ? {label: data?.category.name, value: data?.category.id} : null,    
    adapter_configurator: data ? {label: data?.adapter_configurator.name, value: data?.adapter_configurator.id} : null,

    is_active: data ? data.is_active : false,
  };
  return defaultValues;
};
