import { DynamicForm, FilterSection, IDynamicInputTemplate, IFormDefinition, TypeDynamicInput } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { endOfDay } from "date-fns";
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { date, object } from "yup";

import { ISelectOption } from "interfaces";
import { formatDate, nowDateTime } from "utils";
import { stringIsEmpty } from "utils/utils";

interface ISearch {
	name: string;
	status: string | null;
	dateFrom: Date | null;
	dateTo: Date | null;
}
interface IColXTemplate extends IDynamicInputTemplate {
	xsCol: 'auto' | number | boolean
}
const ColXTemplate = ({ inputLabel, children, xsCol }: IColXTemplate) => {

	return (<Grid item xs={xsCol}>
		{inputLabel && <Typography gutterBottom variant="h6">
			{inputLabel}
		</Typography>
		}
		{children}
	</Grid>)
}

export const FiltersSection = (props: {
	id: string;
	setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
	setFilterStatus: React.Dispatch<React.SetStateAction<string>>;

	setFilterDateFrom: React.Dispatch<React.SetStateAction<Date | null>>;
	setFilterDateTo: React.Dispatch<React.SetStateAction<Date | null>>;
}) => {
	const { t, i18n } = useTranslation();
	const locale = i18n.language.substring(0, 2);

	const { id, setFilterSearch, setFilterStatus, setFilterDateFrom, setFilterDateTo } = props;

	const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
	const [search, setSearch] = useState<string>("");
	const [status, setStatus] = useState<string>("");

	const [dateFrom, setDateFrom] = useState<Date | null>(null);
	const [dateTo, setDateTo] = useState<Date | null>(null);

	useEffect(() => {
		setFiltersOpened(false);
		cleanFilters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	const calculateFiltersCount = useCallback(
		() => {
		// ({ search, status, dateFrom, dateTo }: IFiltersFields) => {
			let count = 0;
			if (!stringIsEmpty(search)) count++;
			if (!stringIsEmpty(status)) count++;
			if (!stringIsEmpty(dateFrom?.toString() || '')) count++;
			if (!stringIsEmpty(dateTo?.toString() || '')) count++;

			return count;
		},
		[search, status, dateFrom, dateTo],
	)

	const debounceChangeName = debounce((cb) => {
		cb();
	}, 350);
	const handleChangeName = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		debounceChangeName(() => {
			setSearch(e.target.value);
		})
	};
	const handleChangeStatus = (
		e: SyntheticEvent<Element, Event>,
		value: ISelectOption
	) => {
		//se existir opção selecionada
		if (value && value.value) {
			setStatus(value.value);
		}
		//se não existir opção selecionada
		else {
			setStatus("");
		}
	};

	const handleChangeDateFrom = (date: Date | null) => {
		// console.log('handleChangeDateFrom ...', date);
		//se existir opção selecionada
		if (date) {
			setDateFrom(date);
		}
		//se não existir opção selecionada
		else {
			setDateFrom(null);
		}
	}
	const handleChangeDateTo = (date: Date | null) => {
		// console.log('handleChangeDateTo ...', date);
		//se existir opção selecionada
		if (date) {
			setDateTo(date);
		}
		//se não existir opção selecionada
		else {
			setDateTo(null);
		}
	}

	const typeOptionsList = [
		{
			label: t("common.info"),
			value: "INFO",
		},
		{
			label: t("common.error"),
			value: "ERROR",
		},
	];

	const template = useCallback(
		({ inputLabel, children }: IDynamicInputTemplate) => {
			return <ColXTemplate xsCol={6} inputLabel={inputLabel} children={children} />
		},
		[],
	)

	// const todayStart = startOfDay(new Date());
	const todayEnd = endOfDay(new Date());
	const dynamicForm = {
		formId: 'form-search',
		inputsDefinition: {
			name: {
				inputType: TypeDynamicInput.text,
				type: 'search',
				label: t("logs.list.user"),
				placeholder: t("common.typeToSearchPlaceholder"),
				defaultValue: '',
				handleOnChange: handleChangeName,
				template: template,
			},
			status: {
				inputType: TypeDynamicInput.autocomplete,
				label: t("logs.list.type"),
				placeholder: t("logs.list.typePlaceholder"),
				options: typeOptionsList,
				defaultValue: null,
				handleOnChange: handleChangeStatus,
				template: template,
			},
			dateFrom: {
				inputType: TypeDynamicInput.datetime,
				label: t("logs.list.dateFrom"),
				placeholder: t("logs.list.dateFromPlaceholder"),
				defaultValue: null,

				maxDate: todayEnd,
				locale: locale,
				labelTextTime: t('calendar.time'),
				labelBtnCancel: t('calendar.cancel'),
				labelBtnConfirm: t('calendar.apply'),
				clearButton: true,

				template: template
			},
			dateTo: {
				inputType: TypeDynamicInput.datetime,
				label: t("logs.list.dateTo"),
				placeholder: t("logs.list.dateToPlaceholder"),
				defaultValue: null,

				maxDate: todayEnd,
				locale: locale,
				labelTextTime: t('calendar.time'),
				labelBtnCancel: t('calendar.cancel'),
				labelBtnConfirm: t('calendar.apply'),
				clearButton: true,

				template: template
			}
		}
	}

	const defaultValues: ISearch = {
		name: dynamicForm.inputsDefinition.name.defaultValue,
		status: dynamicForm.inputsDefinition.status.defaultValue,
		dateFrom: dynamicForm.inputsDefinition.dateFrom.defaultValue,
		dateTo: dynamicForm.inputsDefinition.dateTo.defaultValue,
	};

	const getFiltersSchema = () => {
		return object().shape({
			dateFrom: date()
				.nullable()
				.typeError(
					t("validations.invalidDate", { dateFormat: " HH:mm, yyyy-MM-dd" })
				)
				.max(new Date(), t("validations.invalidMaxDate", { maxDate: nowDateTime("HH:mm, yyyy-MM-dd") })),
			dateTo: date()
				.nullable()
				.typeError(
					t("validations.invalidDate", { dateFormat: "HH:mm, yyyy-MM-dd" })
				)
				.max(new Date(), t("validations.invalidMaxDate", { maxDate: nowDateTime("HH:mm, yyyy-MM-dd") }))
				.when('dateFrom', ([dateFrom], schema) => {
					// console.log(dateFrom);
					if (dateFrom) {
						const dateFromFormatted = formatDate(dateFrom, "HH:mm, yyyy-MM-dd");
						// console.log(dateFromFormatted);
						return schema.min(dateFrom, t("validations.invalidMinDate", { minDate: dateFromFormatted }))
					}
					else {
						return schema;
					}
				})
		});
	}
	const methodsForm = useForm<ISearch>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: defaultValues,

		resolver: yupResolver(getFiltersSchema()),
	});
	const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm }

	const { watch, trigger, formState: { errors: filterErrors } } = methodsForm;
	const watchDateFrom = watch("dateFrom");
	const watchDateTo = watch("dateTo");
	useEffect(() => {
		trigger('dateTo'); // re validate field
		setTimeout(() => {
			// console.log('ferros', filterErrors.dateFrom, !!filterErrors.dateFrom);
			if (!filterErrors.dateFrom && !filterErrors.dateTo) {
				handleChangeDateFrom(watchDateFrom);
			}
		}, 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchDateFrom])
	useEffect(() => {
		trigger('dateFrom'); // re validate field
		setTimeout(() => {
			if (!filterErrors.dateTo) {
				handleChangeDateTo(watchDateTo);
			}
		}, 0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchDateTo])

	useEffect(() => {
		setFilterSearch(search);
	}, [search, setFilterSearch]);

	useEffect(() => {
		setFilterStatus(status);
	}, [status, setFilterStatus]);

	useEffect(() => {
		setFilterDateFrom(dateFrom);
	}, [dateFrom, setFilterDateFrom]);

	useEffect(() => {
		setFilterDateTo(dateTo);
	}, [dateTo, setFilterDateTo]);

	const cleanFilters = () => {
		methodsForm.reset();

		setSearch("");
		setStatus("");
		setDateFrom(null);
		setDateTo(null);
	};

	const showClearFilters = () => {
		return calculateFiltersCount() > 1
	}

	const handleOnToggle = (open: boolean) => {
		setFiltersOpened(open);
	}

	return (
		<>
			<FilterSection
				activeFiltersCount={calculateFiltersCount()}
				filterTitle={t("common.filter")}
				opened={filtersOpened}
				onToggle={handleOnToggle}
			>
				<Grid container gap={1}>
					<Grid item xs={12} sm={showClearFilters() ? 9 : 12}>
						<DynamicForm formDefinition={formDefinition} />
					</Grid>
					{showClearFilters() && (
						<Grid item xs={true} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
							<Button variant="outlined" onClick={cleanFilters}>
								{t("common.cleanFilters")}
							</Button>
						</Grid>
					)}
				</Grid>
			</FilterSection>
		</>
	);
};
