import { TableHeadOrder } from "@alb/live-lib";
import { useEffect, useState } from "react";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IApiResponse, IStream } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, formatOrderBy, ROWS_PER_PAGE } from "utils";

import { StreamsTable } from "./StreamsTable";

const Streams = (props: { refetch: boolean }) => {
  const { refetch } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: streams,
    loading,
    refetch: refetchStreams
  } = useGetAll<IApiResponse<IStream>>(ServiceApiUrl.streamURL, {
    page: page,
    items: ROWS_PER_PAGE,
    order_by: formatOrderBy(order, orderBy),
  });

  useEffect(() => {
    if (refetch) refetchStreams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  let streamsList = <NoData />;

  if (loading) {
    streamsList = <Loading />;
  }

  if (streams && !arrayIsEmpty(streams.data)) {
    streamsList = (
      <StreamsTable
        data={streams}
        orderBy={orderBy}
        order={order}
        loading={loading}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        refetch={refetchStreams}
      />
    );
  }

  return <>{streamsList}</>;
};

export default Streams;
