import React from "react";

import { IAuth } from "interfaces/IAuth";

interface IAuthContext {
  auth: IAuth | null;
  isLoggedIn: boolean;
  login: (auth: IAuth | null, cb?: Function) => void;
  logout: (cb?: VoidFunction) => void;
}
export const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);
