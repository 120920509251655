import { LayoutContext } from "@alb/live-lib";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import AdaptersAdd from "components/Adapters/AdaptersAdd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Adapters from "../components/Adapters/Adapters";

function AdaptersPage() {
  const { t } = useTranslation();

  const { addHeader, addAction, action } = useContext(LayoutContext);
  const [ added, setAdded ] = useState<boolean>(false);

  const header = {
    title: t("pages.adapters.headerText"),
    action: (
      <Button
        variant="contained"
        disableElevation
        startIcon={<AddIcon />}
        onClick={() => addAction(true)}
      >
        {t("adapters.addAdapter")}
      </Button>
    ),
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let adapterAdd;
  if (action) {
    adapterAdd = (
      <AdaptersAdd
        open={action}
        onClose={() => addAction(false)}
        onSuccess={() => {
          addAction(false);
          setAdded(true);
        }}
      />
    );
  }

  return (
    <>
      <Adapters refetch={added} />
      {adapterAdd}
    </>
  );
}

export default AdaptersPage;
