import { LayoutContext } from "@alb/live-lib";
import Summary from "components/Summary/Summary";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

function SummaryPage() {
  const { t } = useTranslation();

  const { addHeader} = useContext(LayoutContext);
  
  const header = {
    title: t("pages.summary.headerText"),    
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Summary />
    </>
  );
}

export default SummaryPage;
