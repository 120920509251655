import { getTheme, Feedback, LayoutContext, ThemeNames } from "@alb/live-lib";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { configure } from "axios-hooks";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouterProvider } from "react-router-dom";

import Loading from "components/Utils/Loading";
import { useAuth } from "hooks/useAuth";
import { sidebar } from "layouts/layout.sidebar";

import { api } from "./providers/AxiosProvider";
import { router } from "./routes/routes";

// loading component for suspense fallback (i18n)
const Loader = () => <Loading />;

const App = () => {
	const authCtx = useAuth();
	const { addSidebar } = useContext(LayoutContext);
	const { t, i18n } = useTranslation();

	const locale = i18n.language.substring(0, 2);
	const theme = getTheme(ThemeNames.Data, locale);

	useEffect(() => {
		configure({ axios: api });
	}, []);

	const logoutHandler = () => {
		// console.log("logoutHandler...", authCtx);
		authCtx.logout();
	};

	useEffect(() => {
		addSidebar(sidebar(t, authCtx.auth, logoutHandler));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authCtx.auth]);

	return (
		<ThemeProvider theme={theme}>
				<CssBaseline />
				<Suspense fallback={<Loader />}>
					<Feedback>
						<RouterProvider router={router} />;
					</Feedback>
				</Suspense>
		</ThemeProvider>
	);
};

export default App;
