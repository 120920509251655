import { IPagination, TablePagination } from '@alb/live-lib';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { ROWS_PER_PAGE } from './consts';

export interface ITablePagination {
	count: number;
	page: number;
	totalPages?: number;
	onPageChange: (
		page: number
	) => void;
}

export const CustomTablePagination = ({ count, page, totalPages, onPageChange }: ITablePagination) => {
	const { t } = useTranslation();
	let _totalPages = totalPages || 1;
	if (!totalPages) {
		_totalPages = Math.ceil(count / ROWS_PER_PAGE);
	}
	const tablePaginationProps: IPagination = {
		count: count,
		page: page,
		totalPages: _totalPages,
		rowsPerPage: ROWS_PER_PAGE,
		i18NLabels: {
			page: t("pagination.page"),
			of: t("pagination.of", { numPages: _totalPages }),
			totalItems: t("pagination.totalItems", { count: count }),
			inputTitle: t("pagination.inputTitle"),
			validation: {
				required: t("pagination.validations.required"),
				positive: t("pagination.validations.positive"),
				min: t("pagination.validations.min"),
				max: t("pagination.validations.max"),
				integer: t("pagination.validations.integer"),
			}
		},
		//porque o index da paginação do mui inicia no 0
		onPageChange: (page: number) => { onPageChange(page + 1) },
	}

	return (
		<TablePagination {...tablePaginationProps} />
	);
}

