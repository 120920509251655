import { LayoutContext } from "@alb/live-lib";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Devices from "components/Devices/Devices";
import DevicesAdd from "components/Devices/DevicesAdd";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function DevicesPage() {
  const { t } = useTranslation();

  const { addHeader, addAction, action } = useContext(LayoutContext);
  const [ added, setAdded ] = useState<boolean>(false);

  const header = {
    title: t("pages.devices.headerText"),
    action: (
      <Button
        variant="contained"
        disableElevation
        startIcon={<AddIcon />}
        onClick={() => addAction(true)}
      >
        {t("devices.addDevice")}
      </Button>
    ),
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let deviceAdd;
  if (action) {
    deviceAdd = (
      <DevicesAdd
        open={action}
        onClose={() => addAction(false)}
        onSuccess={() => {
          addAction(false);
          setAdded(true);
        }}
      />
    );
  }

  return (
    <>
      <Devices refetch={added} />
      {deviceAdd}
    </>
  );
}

export default DevicesPage;
