import { InputCheckbox, InputSelect, RequiredField, InputText } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { useGetAll } from "hooks/fetchData/useGetAll";
import {
	IAdapter,
	IAdapterForm,
	IApiResponse,
	IPartner,
	ISelectOption,
	ITenant,
} from "interfaces";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formDefaultValues } from "./formDefaultValues";
import { getAdapterFormSchema } from "./validations";
import { RequiredFields } from "components/Utils/RequiredField";

const AdaptersAddForm = (props: {
	formId: string;
	data?: IAdapter;
	onFormSubmit: (payLoad: IAdapterForm) => void;
	setFormIsValidStatus?: (status: boolean) => void;
}) => {
	const { t } = useTranslation();

	const { formId, data, onFormSubmit, setFormIsValidStatus } = props;

	const defaultSelect: ISelectOption[] = [];
	const loadingOption: ISelectOption[] = useMemo(() => [{ label: t('common.labelLoading'), value: '', disabled: true }], [t]);

	// get list of tenants
	const {
		data: tenants,
		loading: loadingTenants,
	} = useGetAll<IApiResponse<ITenant>>(ServiceApiUrl.tenantURL);

	// get list of partners
	const {
		data: partners,
		loading: loadingPartners,
	} = useGetAll<IApiResponse<IPartner>>(ServiceApiUrl.partnerURL);

	const [partnersOptions, setPartnersOptions] =
		useState<ISelectOption[]>(defaultSelect);
	useEffect(() => {
		if (partners) {
			const pOpts = partners.data.map((p: IPartner) => ({
				label: p.name,
				value: p.id,
			}));
			setPartnersOptions(pOpts);
		}
	}, [partners]);

	useEffect(() => {
		if (loadingPartners) {
			setPartnersOptions(loadingOption);
		}
	}, [loadingPartners, loadingOption])

	const [tenantsOptions, setTenantsOptions] =
		useState<ISelectOption[]>(defaultSelect);
	useEffect(() => {
		if (tenants) {
			const tOpts = tenants.data.map((p: ITenant) => ({
				label: p.name,
				value: p.id,
			}));
			setTenantsOptions(tOpts);
		}
	}, [tenants]);
	useEffect(() => {
		if (loadingTenants) {
			setTenantsOptions(loadingOption);
		}
	}, [loadingTenants, loadingOption])

	const defaultValues = formDefaultValues(data);

	const methodsForm = useForm<IAdapterForm>({
		defaultValues: defaultValues,
		mode: "onChange",
		reValidateMode: "onChange",
		resolver: yupResolver(getAdapterFormSchema()),
	});

	const { control, handleSubmit, setValue } = methodsForm;

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IAdapterForm> = (payLoad: IAdapterForm) => {
		onFormSubmit(payLoad);
	};

	useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [methodsForm.formState.isValid, setFormIsValidStatus]);

	return (
		<>
			<FormProvider {...methodsForm}>
				<form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<RequiredField title={t("adapters.form.nameLabel")} />
							<InputText
								control={control}
								autoFocus={true}
								name="name"
								placeholder={t("adapters.form.namePlaceholder")}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom variant="h6">
								{t("adapters.form.descriptionLabel")}
							</Typography>
							<InputText
								control={control}
								name="description"
								placeholder={t("adapters.form.descriptionPlaceholder")}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom variant="h6">
								{t("adapters.form.gatewayUrlLabel")}
							</Typography>
							<InputText
								control={control}
								name="gateway_url"
								placeholder={t("adapters.form.gatewayUrlPlaceholder")}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="h6">
								{t("adapters.form.gatewayUserLabel")}
							</Typography>
							<InputText
								control={control}
								name="gateway_user"
								placeholder={t("adapters.form.gatewayUserPlaceholder")}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="h6">
								{t("adapters.form.gatewayPasswordLabel")}
							</Typography>
							<InputText
								control={control}
								type="password"
								name="gateway_password"
								placeholder={t("adapters.form.gatewayPasswordPlaceholder")}
							/>
						</Grid>
						<Grid item xs={4}>
							<RequiredField title={t("adapters.form.domainLabel")} />
							<InputText
								control={control}
								name="domain"
								placeholder={t("adapters.form.domainPlaceholder")}
							/>
						</Grid>
						<Grid item xs={4}>
							<RequiredField title={t("adapters.form.domainDevoLabel")} />
							<InputText
								control={control}
								name="domain_devo"
								placeholder={t("adapters.form.domainDevoPlaceholder")}
							/>
						</Grid>
						<Grid item xs={4}>
							<RequiredField title={t("adapters.form.partnerDevoLabel")} />
							<InputText
								control={control}
								name="partner_devo"
								placeholder={t("adapters.form.partnerDevoPlaceholder")}
							/>
						</Grid>
						<Grid item xs={6}>
							<RequiredField title={t("adapters.form.partnerLabel")} />
							<InputSelect
								control={control}
								name="partner"
								options={partnersOptions}
							/>
						</Grid>
						<Grid item xs={6}>
							<RequiredField title={t("adapters.form.tenantLabel")} />
							<InputSelect
								control={control}
								name="tenant"
								options={tenantsOptions}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="h6">
								{t("adapters.form.isActiveLabel")}
							</Typography>
							<InputCheckbox
								control={control}
								name="is_active"
								setValue={setValue}
								checked={defaultValues.is_active}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="h6">
								{t("adapters.form.protectedIngestionAtLabel")}
							</Typography>
							<InputCheckbox
								control={control}
								name="protected_ingestion"
								setValue={setValue}
								checked={defaultValues.protected_ingestion}
							/>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
			<RequiredFields />
		</>
	);
};

export default AdaptersAddForm;
