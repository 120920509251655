import { generatePath, Location, Params } from "react-router-dom";

/**
 * Function converts path like /user/123 to /user/:id
 */
function getRoutePath(location: Location, params: Params): string {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
}

function formatRoutePath(
  location: Location,
  params: Params,
  paramsValues: Params
): string {
  let _path = getRoutePath(location, params);

  // _path pode vir sem os params (:id) então crio a path com os params seguidos...
  if (!Object.keys(params).length) {
    Object.entries(paramsValues).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        _path = `${_path}/:${paramName}`;
      }
    });
  }

  _path = generatePath(_path, paramsValues);

  return _path;
}

export { getRoutePath, formatRoutePath };
