import { object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 100,
    unit: 32,
  },
  min: {
    name: 3,
    unit: 1,
  },
};

export const getStreamFormSchema = (nameValidationMsg: string) =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .trim()
      .matches(/^[a-zA-Z]\w+$/, nameValidationMsg)
      .required(),

    description: string(),
    
    category: object().required(),      

    adapter_configurator: object().required(),      

    unit: string()
      .min(InputsLengths.min.unit)
      .max(InputsLengths.max.unit)
      .required(),
  });
