import { Paper, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IStats } from "interfaces";
import React from "react";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  ...theme.typography.body2,
  padding: theme.spacing(1),
}));

const GlobalItem = (props: {
  title: string;
  subTitle: string;
  value: number;
}) => {
  const { title, subTitle, value } = props;

  return (
    <Grid xs={12} sm={6} md={4} lg={3}>
      <Item>
        <Grid container spacing={2}>
          <Grid flexGrow={1}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="caption">{subTitle}</Typography>
          </Grid>
          <Grid>
            <Typography variant="h2">{value}</Typography>
          </Grid>
        </Grid>
      </Item>
    </Grid>
  );
};

const GlobalValues = () => {
  const { t } = useTranslation();
  
  const {
    data: stats,
    loading,  
  } = useGetAll<IStats[]>(ServiceApiUrl.statisticURL);
 
  let totals = <NoData />;

  if (loading) {
    totals = <Loading />;
  }
  if (stats && !arrayIsEmpty(stats)) {
    totals = (
      <Grid container spacing={2} mt={3}>
        {stats.map((s: IStats, idx: number) => (
          <GlobalItem
            key={`${s.name}-${idx}`}
            title={`${t(`summary.global.${s.name}`)}`}
            subTitle={t("summary.total")}
            value={s.count}
          />
        ))}
      </Grid>
    );
  }
  return <>{totals}</>;
};

export default GlobalValues;
