import LockIcon from "@mui/icons-material/Lock";
import { Box, Button, Grid, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import { RoutePaths } from "routes/routes";

const Unauthorized = (props: any) => {
	const { t } = useTranslation();
	const authCtx = useAuth();
	const navigate = useNavigate();
	const goBack = () => navigate(-1);
	const goLogin = () => {
		// fazer logout e redirect para login...
		authCtx.logout(() => window.location.replace(RoutePaths.LOGIN));
	};

	return (
		<>
			<CssBaseline />
			<Grid container sx={{ textAlign: "center" }}>
				<Grid item xs={true} mt={12}>
					<LockIcon color="error" sx={{ fontSize: 80 }} />

					<Typography mt={2} variant="h3">
						{t("common.unauthorizedTitle")}
					</Typography>

					<Typography mb={2} mt={6} variant="h5">
						{t("common.unauthorizedDescription")}
					</Typography>

					<Box mt={10}>
						<Button onClick={goBack} size="large" variant="outlined">
							{t("common.goBack")}
						</Button>
						<Button
							onClick={goLogin}
							size="large"
							variant="outlined"
							style={{
								margin: "5px",
							}}
						>
							{t("common.goLogin")}
						</Button>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};
export default Unauthorized;
