import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import StorageIcon from "@mui/icons-material/Storage";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { TFunction } from "i18next";

import { MenuUserItems } from "data/LeftSidebarMenuUserItems";
import { IAuth } from "interfaces/IAuth";

import Logo from "../assets/images/live-data/logo.svg";
import SmallLogo from "../assets/images/live-data/small_logo.svg";

export const sidebar = (t: TFunction, auth: IAuth | null, logoutHandler: Function) => {

	function translateMenuItem(item: { title: string; tooltip?: string }) {
		return {
			title: t(item.title),
			tooltip: item.tooltip ? t(item.tooltip) : item.tooltip,
		};
	}

	const menuItems = [
		{
			title: "sidebar.menu.core",
			tooltip: "sidebar.menu.core",
			icon: <StorageIcon />,
			subItems: [
				{
					title: "sidebar.menu.summary",
					path: "/summary",
					permissions: [],
				},
				{
					title: "sidebar.menu.adapters",
					path: "/adapters",
					permissions: [],
				},
				{
					title: "sidebar.menu.accounts",
					path: "/accounts",
					permissions: [],
				},
				{
					title: "sidebar.menu.tenant",
					path: "/tenants",
					permissions: [],
				},
				{
					title: "sidebar.menu.partners",
					path: "/partners",
					permissions: [],
				},
				{
					title: "sidebar.menu.devices",
					path: "/devices",
					permissions: [],
				},
				{
					title: "sidebar.menu.streams",
					path: "/streams",
					permissions: [],
				},
				{
					title: "sidebar.menu.categories",
					path: "/categories",
					permissions: [],
				},
			],
		},
		{
			title: "sidebar.menu.logs",
			tooltip: "sidebar.menu.logs",
			icon: <TextSnippetIcon />,
			subItems: [
				{
					title: "sidebar.menu.urban",
					path: "/logs/urban",
					permissions: [],
				},
				{
					title: "sidebar.menu.green",
					path: "/logs/green",
					permissions: [],
				},
			],
		},
		{
			title: "sidebar.menu.reader",
			tooltip: "sidebar.menu.reader",
			icon: <LibraryBooksIcon />,
			path: "/dashboard",
		},
		{
			title: "sidebar.menu.writer",
			tooltip: "sidebar.menu.writer",
			path: "/dashboard",
			icon: <DriveFileRenameOutlineIcon />,
		},
	];
	const items = menuItems.map((item) => ({
		...item,
		...translateMenuItem(item),
		subItems: item.subItems
			? item.subItems.map((item) => ({
				...item,
				...translateMenuItem(item),
			}))
			: [],
	}));

	const menuUser = MenuUserItems.map((item) => ({
		...item,
		...translateMenuItem(item),
	}));

	// add logout option
	const logoutItem = {
		title: t("common.logout"),
		onClick: logoutHandler,
	};

	const userInfo = {
		user: {
			firstName: `${auth?.first_name} ${auth?.last_name}`,
		},
	};

	return {
		logo: Logo,
		smallLogo: SmallLogo,
		items,
		auth: userInfo,
		menuUser: [...menuUser, logoutItem],
		extraLogos: [],
	};
};
