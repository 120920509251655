import { asterisk, RequiredFieldsText } from "@alb/live-lib";
import { Trans, useTranslation } from "react-i18next";

interface IRequiredFields {
  allRequired?: boolean;
}

const RequiredFields = ({ allRequired }: IRequiredFields) => {
  const { t } = useTranslation();
  return (
    <RequiredFieldsText
      text={
        allRequired ? (
          t("allFieldsRequired")
        ) : (
          <Trans i18nKey="requiredFields" components={{ style: asterisk }} />
        )
      }
    />
  );
};

export {
  RequiredFields,
};
