import { useNotification } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useCreate } from "hooks/fetchData/useCreate";
import { ITenant } from "interfaces";
import { IDialogProps } from "interfaces/IDialogProps";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import TenantsForm from "./TenantsForm";
import { useState } from "react";

const TenantsAdd = ({ open, onClose, onSuccess }: IDialogProps) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const addFormId = "add-tenant-form";

	// colocar o botão guardar a disabled
  const [formIsValid, setFormIsValid] = useState(false);
  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  //pedido para criar tenant
  const {
    loading,
    refetch: createTenant,
  } = useCreate<ITenant>(ServiceApiUrl.tenantURL);

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose(); //fecha modal
    addNotification({
      message: t("tenants.tenantAdded"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: ITenant) => {
    // console.log(payLoad);
    try {
      await createTenant({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("tenants.addTenant")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <TenantsForm formId={addFormId} onFormSubmit={formSubmitHandler} setFormIsValidStatus={getFormIsValidStatus} />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={"submit"}
          form={addFormId}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TenantsAdd;
