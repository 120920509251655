import { ILoginFormInput, IRecoverPasswordArgs, IRecoverPasswordFormInput, Login as LoginUI } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useCreate } from "hooks/fetchData/useCreate";
import { useAuth } from "hooks/useAuth";
import { IAuth } from "interfaces/IAuth";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";

// @ts-ignore
import logo from "../../assets/images/live-data/logo.svg";
import { getLoginFormSchema, getRecoverFormSchema } from "./validations";

function Login() {
  const authCtx = useAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();
	// redirect after login...
  let to = RoutePaths.SUMMARY;

  //pedido para fazer login
  const {
    data: authInfo,
    refetch: login,
  } = useCreate<IAuth>(ServiceApiUrl.loginURL);

  // -------------------------------------------------
  // recover password component
  const argsRecoverPassword: IRecoverPasswordArgs = {
    title: t("passwordRecovery.title"),
    message: t("loginForm.messageForgotPassword"),
    cancelLabel: t("common.cancel"),
    confirmLabel: t("common.send"),
    validator: yupResolver(getRecoverFormSchema()),
    open: false,
    loading: false,
    onClose: ()=>{},
    onSubmit: (data: IRecoverPasswordFormInput)=> {},
  };

  // -------------------------------------------------
  // login component
  useEffect(() => {
    if (authInfo) {
      authCtx.login(authInfo, () => {
        navigate(to, { replace: true });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo]);

  const onSubmitLogin = async (payLoad: ILoginFormInput) => {
    try {
      await login({ data: payLoad });
    } catch (error) {}
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={8}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoginUI
          logo={logo}
          alt="live!Data"
          title={t("loginForm.welcome")}
          recoverLabel={''}
          buttonLabel={t("loginForm.login")}
          validator={yupResolver(getLoginFormSchema())}
          onSubmit={onSubmitLogin}
          onClick={ (() => {})}
          recoverPassword={argsRecoverPassword}
        />
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundColor: "#FDF5E7",
        }}
      />
    </Grid>
  );
}

export default Login;
