import StreamsView from "components/Streams/StreamsView";

function StreamsViewPage() {
  return (
    <>
      <StreamsView />
    </>
  );
}

export default StreamsViewPage;
