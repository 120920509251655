import { MiniSelect } from "@alb/live-lib";
import { debounce } from "@mui/material/utils";
import React, { useEffect } from "react";

import { useGetAll } from "hooks/fetchData/useGetAll";
import { IApiResponse, ISelectOption } from "interfaces";
import { ROWS_PER_PAGE } from "utils";

interface IFilterSelect {
	onSelected: (value: ISelectOption | null) => void;
	apiUrl: string;
	selectedValue?: string;
}

interface IBase {
	name: string,
	id: string
}

const FindSelectedOption = (props: { options: ISelectOption[], selectedOption?: string }) => {
	const {options, selectedOption} = props;

	const option = React.useMemo(() => {
		return options.find((option: ISelectOption) => option.value === selectedOption) || '';
	}, [options, selectedOption]);

	return option;
};

export const FilterSelect = <T extends IBase,>(props: IFilterSelect): JSX.Element => {

	const { selectedValue, onSelected, apiUrl } = props;

	const [page, setPage] = React.useState(1);
	const [totalPages, setTotalPages] = React.useState(1);
	const [inputValue, setInputValue] = React.useState("");
	const [position, setPosition] = React.useState(0);
	const [listboxNode, setListboxNode] = React.useState<any>("");

	const [options, setOptions] = React.useState<ISelectOption[]>([]);

	const [showSearchBar, setShowSearchBar] = React.useState<boolean>(false);
	const [searchOnServer, setSearchOnServer] = React.useState<boolean>(true);

	const [manual, setManual] = React.useState<boolean>(true);

	const { data, loading } = useGetAll<IApiResponse<T>>(
		apiUrl,
		{
			page: page,
			items: ROWS_PER_PAGE,
			contains: inputValue,
		},
		{
			manual: manual
		}
	);

	useEffect(() => {
		if (!data) return undefined;

		setTotalPages(data.totalPages);
		setShowSearchBar(inputValue !== '' || data.totalCount > 5)
		setSearchOnServer(inputValue !== '' || data.totalPages > page)

		const opts = data.data.map((a: T) => {
			return { label: a.name, value: a.id };
		});
		let newOptions = opts;
		if (page > 1) {
			newOptions = [...options, ...opts];
		}
		setOptions(newOptions);

		const position = listboxNode.scrollTop + listboxNode.clientHeight;
		setPosition(position);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (inputValue === "") {
			return undefined;
		}

		setOptions([]);
		setPage(1);
	}, [inputValue]);

	const loadMoreResults = () => {
		const nextPage = page + 1;
		if (nextPage > totalPages) return undefined;

		setPage(nextPage);
	};

	// change scroll position
	useEffect(() => {
		if (listboxNode !== "" && position >= 0) {
			listboxNode.scrollTop = position;
		}
	}, [position, listboxNode]);

	const handleOnOpen = (event: React.SyntheticEvent<Element, Event>) => {
		setInputValue("");
		setManual(false);
	}

	const handleOnScroll = (event: React.SyntheticEvent<Element, Event>) => {
		const _listboxNode = event.currentTarget;

		const position = _listboxNode.scrollTop + _listboxNode.clientHeight;
		if (_listboxNode.scrollHeight - position < 1) {
			setListboxNode(_listboxNode);

			loadMoreResults();
		}
	};
	const debounceData = debounce((cb) => {
		cb();
	}, 350);
	const handleOnInputChange = (
		event: React.SyntheticEvent<Element, Event>,
		value: string
	) => {
		debounceData(() => {
			setInputValue(value);
		});
	};

	const getSelectedOption = () => {
		return FindSelectedOption({options, selectedOption: selectedValue});
	}

	return (
		<MiniSelect
			options={options}
			showBorder={false}
			showSearchBar={showSearchBar}
			value={getSelectedOption()}
			showLoading={loading}
			searchOnServer={searchOnServer}
			onChangeOption={onSelected}
			onScroll={handleOnScroll}
			onSearch={handleOnInputChange}
			onOpen={handleOnOpen}
		/>
	);
};

export default FilterSelect;
