import { LayoutProvider } from "@alb/live-lib";
import React from "react";
import ReactDOM from "react-dom/client";

import { AuthContextProvider } from "providers/AuthContextProvider";

import App from "./App";

// import i18n (needs to be bundled ;))
import "./i18n/config";
import "./styles/main.scss";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<LayoutProvider>
			<AuthContextProvider>
				<App />
			</AuthContextProvider>
		</LayoutProvider>
	</React.StrictMode>
);
