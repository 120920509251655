import { TableHeadOrder } from "@alb/live-lib";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import ErrorHandlerMain from "components/Errors/ErrorHandler";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IApiResponse, ILogs } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, formatOrderBy, ROWS_PER_PAGE } from "utils";

import { FiltersSection } from "./FiltersSection";
import { LogsTable } from "./LogsTable";

const Logs = (props: { id: string }) => {
	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("desc");
	const [orderBy, setOrderBy] = useState<string>("log_date");
	const [filterSearch, setFilterSearch] = useState<string>('');
	const [filterType, setFilterType] = useState<string>('');

	const [filterDateFrom, setFilterDateFrom] = useState<Date| null>(null);
	const [filterDateTo, setFilterDateTo] = useState<Date| null>(null);
	const {
		data: logs,
		loading,
		error
	} = useGetAll<IApiResponse<ILogs>>(ServiceApiUrl.getLogsURL(props.id), GetParams(), { manual: false });

	function GetParams() {
		var params: {
			page: number
			items: number
			order_by: string
			[key: string]: string | unknown
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			...(filterType !== '' && { type: filterType }),
			...(filterSearch !== '' && { username: filterSearch }),
			...(filterDateFrom && { from: filterDateFrom }),
			...(filterDateTo && { to: filterDateTo }),
		}
		return params
	}
	function reset(){
		setPage(1);
		setOrder("desc");
		setOrderBy("log_date");
	}

	useEffect(() => {
		reset();
	}, [props.id, filterSearch, filterType])

	let logsList = <NoData />;

	if (loading) {
		logsList = <Loading />;
	}

	if (error) {
		logsList = <ErrorHandlerMain error={error} />
	}

	if (!loading && !error && logs && !arrayIsEmpty(logs.data)) {
		logsList = (
			<LogsTable
				data={logs}
				orderBy={orderBy}
				order={order}
				loading={loading}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
			/>
		);
	}

	return (
		<Box mt={4}>
			<FiltersSection
				id={props.id}
				setFilterSearch={setFilterSearch}
				setFilterStatus={setFilterType}
				setFilterDateFrom={setFilterDateFrom}
				setFilterDateTo={setFilterDateTo}
			/>
			{logsList}
		</Box>)

};

export default Logs;
