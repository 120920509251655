import TenantsView from "components/Tenants/TenantsView";

function TenantsViewPage() {
  return (
    <>
      <TenantsView />
    </>
  );
}

export default TenantsViewPage;
