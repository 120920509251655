import { useRouteError } from 'react-router-dom';

import ErrorHandlerMain from 'components/Errors/ErrorHandler';

function ErrorPage() {
  const error = useRouteError();
  return (
    <ErrorHandlerMain error={error} />
  );
}

export default ErrorPage;
