import { InputCheckbox, RequiredField, InputText } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IPartner } from "interfaces";

import { formDefaultValues } from "./formDefaultValues";
import { getPartnerFormSchema } from "./validations";
import { RequiredFields } from "components/Utils/RequiredField";

const PartnersForm = (props: {
	formId: string;
	data?: IPartner;
	onFormSubmit: (payLoad: IPartner) => void;
	setFormIsValidStatus?: (status: boolean) => void;
}) => {
	const { t } = useTranslation();
	const { formId, data, onFormSubmit, setFormIsValidStatus } = props;

	const defaultValues = formDefaultValues(data);

	const methodsForm = useForm<IPartner>({
		defaultValues: defaultValues,
		mode: "onChange",
		reValidateMode: "onChange",
		resolver: yupResolver(getPartnerFormSchema()),
	});

	const { control, handleSubmit, setValue } = methodsForm;

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IPartner> = (payLoad: IPartner) => {
		onFormSubmit(payLoad);
	};

	useEffect(() => {
		if (setFormIsValidStatus)
			setFormIsValidStatus(methodsForm.formState.isValid);
	}, [methodsForm.formState.isValid, setFormIsValidStatus]);


	return (
		<>
			<FormProvider {...methodsForm}>
				<form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<RequiredField title={t("partners.form.nameLabel")} />
							<InputText
								control={control}
								autoFocus={true}
								name="name"
								placeholder={t("partners.form.namePlaceholder")}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography gutterBottom variant="h6">
								{t("partners.form.descriptionLabel")}
							</Typography>
							<InputText
								control={control}
								name="description"
								placeholder={t("partners.form.descriptionPlaceholder")}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography gutterBottom variant="h6">
								{t("partners.form.isActiveLabel")}
							</Typography>
							<InputCheckbox
								control={control}
								name="is_active"
								setValue={setValue}
								checked={defaultValues.is_active}
							/>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
			<RequiredFields />
		</>
	);
};

export default PartnersForm;
