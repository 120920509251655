import { TableCell, TableRow, Popover, IconButton, Typography, Stack } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ChipStatus } from "components/Utils/ChipStatus";
import { ILogs, LogType } from "interfaces";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate, typeColor } from "utils";
import { TableCellTruncate } from "styles/component-styles";

const LogsTableRow = (props: {
	row: ILogs;
}) => {
	const { row } = props;
	const { t } = useTranslation();

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const showLogMessage = Boolean(anchorEl);
	const id = showLogMessage ? 'log-message-popover' : undefined;

	let errorMessage = null;
	if (row.type === LogType.ERROR && row.message) {
		errorMessage = <>
			<IconButton aria-describedby={id} color={'primary'} onClick={handleClick}>
				<VisibilityIcon />
			</IconButton>
			<Popover id={id}
				open={showLogMessage}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				PaperProps={{ sx: { maxWidth: '400px' } }}>
				<Typography sx={{ p: 2 }}>{row.message}</Typography>
			</Popover>
		</>
	}
	return (
		<TableRow onClick={(e): void => {
			e.stopPropagation();
		}}>
			<TableCell>
				<Stack direction="row" spacing={1}>
					<ChipStatus
						label={row.type}
						color={
							typeColor(row.type)
						}
					/>
					{errorMessage}
				</Stack>
			</TableCell>
			<TableCell>{row.username}</TableCell>
			<TableCellTruncate maxwidth={400} >{row.request}</TableCellTruncate>
			<TableCell>
				{formatDate(row.log_date, t("calendar.dateTimeFormatLocalSeconds"))}
			</TableCell>

		</TableRow>
	);
};

export default LogsTableRow;
