import { IAdapter, IDevice, IDeviceForm } from "interfaces";

export const formDefaultValues = (data?: IDevice) => {
  const latLng = data?.geolocation.iconPosition;

  const defaultValues: IDeviceForm = {
    name: data?.name || "",
    description: data?.description || "",
    external_id: data?.external_id || "",
    geolocation: data?.geolocation.iconPosition.join() || "",

    latitude: latLng?.[0] || 0,
    longitude: latLng?.[1] || 0,

    extra_params: JSON.stringify(data?.extra_params) || "",

    adapter_configurators: data?.adapter_configurators.map((a: IAdapter) => {return {label: a.name, value: a.id}}) || [],

    is_active: (data ? data.is_active : false),
  };
  return defaultValues;
};
