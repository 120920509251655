import { TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IAdapter, IApiResponse } from "interfaces";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, CustomTablePagination, formatOrderBy, ROWS_PER_PAGE } from "utils";

import AdapterTableRow from "./AdaptersTableRow";

const AdaptersTabList = (props: { filters?: any }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");

	const { data: adapters, loading } = useGetAll<IApiResponse<IAdapter>>(ServiceApiUrl.adapterURL,
		{
			...props?.filters,
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
		});

	let adaptersList = <NoData />;
	if (loading) {
		adaptersList = <Loading />;
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage);
	};

	const handleRowClick = (row: IAdapter) => {
		navigate(`${RoutePaths.ADAPTERS}/${row.id}`);
	}

	const tableHeads: TableHeadCell[] = [
		{
			id: "name",
			label: t("common.name"),
		},
		{
			id: "is_active",
			label: t("common.statusLabel"),
		},
		{
      id: "domain",
      label: t("common.domain"),
    },
    {
      id: "partner",
      label: t("adapters.list.partner"),
    },
		{
			id: "updated_at",
			label: t("common.updatedAt"),
		}
	];

	if (adapters && !arrayIsEmpty(adapters.data)) {
		let row;
		if (!arrayIsEmpty(adapters.data)) {
			row = adapters.data.map((adapter: IAdapter) => (
				<AdapterTableRow
					key={adapter.id}
					row={adapter}
					onRowClick={handleRowClick}
				/>
			));
		}

		adaptersList = (
			<>
				<TableContainer>
					<Table>
						<TableHeadSort
							headCells={tableHeads}
							onRequestSort={handleRequestSort}
							order={order}
							orderBy={orderBy}
							loading={loading}
						/>
						<TableBody>{row}</TableBody>
					</Table>
				</TableContainer>

				<CustomTablePagination
					count={adapters.totalCount}
					page={page}
					totalPages={adapters.totalPages}
					onPageChange={handleChangePage}
				/>
			</>
		);
	}

	return <>{adaptersList}</>;

};

export default AdaptersTabList;
