import { ITenant } from "interfaces";

export const formDefaultValues = (data?: ITenant) => {
  
  const defaultValues = {
    name: data?.name || "",
    is_active: (data ? data.is_active : false)    
  };
  return defaultValues;
};
