
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IApiResponse, IStream } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useNavigate } from "react-router-dom";

import { arrayIsEmpty, CustomTablePagination, formatOrderBy, ROWS_PER_PAGE } from "utils";
import StreamsTableRow from "./StreamsTableRow";
import { RoutePaths } from "routes/routes";

const StreamsTabList = (props: { filters?: any }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");

	const {
		data: streams,
		loading,
	} = useGetAll<IApiResponse<IStream>>(ServiceApiUrl.streamURL, {
		...props?.filters,
		page: page,
		items: ROWS_PER_PAGE,
		order_by: formatOrderBy(order, orderBy),
	});

	let streamsList = <NoData />;
	if (loading) {
		streamsList = <Loading />;
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage);
	};

	const handleRowClick = (row: IStream) => {
		navigate(`${RoutePaths.STREAMS}/${row.id}`);
	}

	const tableHeads: TableHeadCell[] = [
		{
			id: "name",
			label: t("common.name"),
		},
		{
			id: "category",
			label: t("common.category"),
		},
		{
			id: "unit",
			label: t("common.unit"),
		},
		{
			id: "is_active",
			label: t("common.statusLabel"),
		},
		{
			id: "created_at",
			label: t("common.createdAt"),
		},
		{
			id: "updated_at",
			label: t("common.updatedAt"),
		}
	];

	if (streams && !arrayIsEmpty(streams.data)) {
		let row;
		if (!arrayIsEmpty(streams.data)) {
			row = streams.data.map((stream: IStream) => (
				<StreamsTableRow
					key={stream.id}
					row={stream}
					hiddenCols={['adapter_configurator__name']}
					onRowClick={handleRowClick}
				/>
			));
		}

		streamsList = (
			<>
				<TableContainer>
					<Table>
						<TableHeadSort
							headCells={tableHeads}
							onRequestSort={handleRequestSort}
							order={order}
							orderBy={orderBy}
							loading={loading}
						/>
						<TableBody>{row}</TableBody>
					</Table>
				</TableContainer>

				<CustomTablePagination
					count={streams.totalCount}
					page={page}
					totalPages={streams.totalPages}
					onPageChange={handleChangePage}
				/>
			</>
		);
	}

	return <>{streamsList}</>;

};

export default StreamsTabList;
