import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { ICategory } from "interfaces";

const CategoriesTableRow = (props: {
  row: ICategory;
  onDelete: (row: ICategory) => void;
  onEdit: (row: ICategory) => void;
}) => {
  const { row, onDelete, onEdit } = props;
  const { t } = useTranslation();

  return (
    <>
      <TableRow>
        <TableCell>{row.name}</TableCell>

        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          <IconButton
            title={t("common.edit")}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              onEdit(row);
            }}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            title={t("common.delete")}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              onDelete(row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CategoriesTableRow;
