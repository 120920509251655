import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import Login from "components/Login/login";

function LoginPage() {
  const authCtx = useAuth();
  // if authenticated navigate
  if (authCtx.isLoggedIn) {
    return <Navigate to="/" replace />
  }

  return (
    <Login />
  );
}

export default LoginPage;
