import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useEffect } from "react";

export function useGetAll<T = any>(
  url: string,
  params?: any,
  options?: { manual: boolean },
  othersConfig?: AxiosRequestConfig<any>
) {
  const { sendErrorMessage } = useErrorHandler();

  const config: AxiosRequestConfig = {
    ...othersConfig,
    url,
    params: { ...params },
  };

  const [{ data, loading, error }, refetch, cancelRequest] = useAxios<T>(
    config,
    { manual: options?.manual }
  );

  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error, sendErrorMessage]);

  return { data, loading, error, refetch, cancelRequest };
}
