import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";

import NotFound from "components/Errors/NotFound";
import About from "pages/About";
import AccountsPage from "pages/Accounts";
import AccountsViewPage from "pages/AccountsView";
import AdaptersPage from "pages/Adapters";
import AdaptersViewPage from "pages/AdaptersView";
import CategoriesPage from "pages/Categories";
import DevicesPage from "pages/Devices";
import DevicesViewPage from "pages/DevicesView";
import ErrorPage from "pages/Error";
import LoginPage from "pages/Login";
import LogsPage from "pages/Logs";
import PartnersPage from "pages/Partners";
import PartnersViewPage from "pages/PartnersView";
import StreamsPage from "pages/Streams";
import StreamsViewPage from "pages/StreamsView";
import SummaryPage from "pages/Summary";
import TenantsPage from "pages/Tenants";
import TenantsViewPage from "pages/TenantsView";
import Unauthorized from "pages/Unauthorized";

import RequireAuth from "./auth-routes";

export const RoutePaths = {
  LOGIN: "/login",
  SUMMARY: "/summary",
  ADAPTERS: "/adapters",
  ACCOUNTS: "/accounts",
  PARTNERS: "/partners",
  TENANTS: "/tenants",
  DEVICES: "/devices",
  STREAMS: "/streams",
  CATEGORIES: "/categories",
  ABOUT: "/about",
	LOGS: "/logs",
	UNAUTHORIZED: "/unauthorized"
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path={RoutePaths.LOGIN} element={<LoginPage />} />
      <Route path="/" element={<Navigate to={RoutePaths.SUMMARY} replace />} />

      <Route element={<RequireAuth />}>
        <Route path={RoutePaths.SUMMARY} element={<SummaryPage />} />

        <Route path={RoutePaths.ADAPTERS} element={<AdaptersPage />} />
        <Route path={`${RoutePaths.ADAPTERS}/:id`} element={<AdaptersViewPage />} />

        <Route path={RoutePaths.ACCOUNTS} element={<AccountsPage />} />
        <Route path={`${RoutePaths.ACCOUNTS}/:id`} element={<AccountsViewPage />} />

        <Route path={RoutePaths.PARTNERS} element={<PartnersPage />} />
        <Route path={`${RoutePaths.PARTNERS}/:id`} element={<PartnersViewPage />} />

        <Route path={RoutePaths.TENANTS} element={<TenantsPage />} />
        <Route path={`${RoutePaths.TENANTS}/:id`} element={<TenantsViewPage />} />

        <Route path={RoutePaths.DEVICES} element={<DevicesPage />} />
        <Route path={`${RoutePaths.DEVICES}/:id`} element={<DevicesViewPage />} />

        <Route path={RoutePaths.STREAMS} element={<StreamsPage />} />
        <Route path={`${RoutePaths.STREAMS}/:id`} element={<StreamsViewPage />} />

        <Route path={RoutePaths.CATEGORIES} element={<CategoriesPage />} />

				<Route path={`${RoutePaths.LOGS}/:id`} element={<LogsPage />} />

        <Route path={RoutePaths.ABOUT} element={<About />} />
      </Route>

      <Route path="*" element={<NotFound />} />
			<Route path="/unauthorized" element={<Unauthorized />} />
    </Route>
  )
);

// export const AppRoutes = (props: any) => {
//   const [state, setState] = useState({
//     action: props.history.action,
//     location: props.history.location,
//   });

//   useLayoutEffect(() => props.history.listen(setState), [props.history]);

//   return (
//     <Router
//       {...props}
//       location={state.location}
//       navigationType={state.action}
//       navigator={props.history}
//     >
//       <Routes>
//         <Route path="/login" element={<LoginPage />} />

//         <Route element={<RequireAuth />} errorElement={<ErrorPage />}>
//           <Route path="/" element={<Map />} />
//           <Route path="/adapters" element={<AdaptersPage />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/about" element={<About />} />
//         </Route>

//         <Route path="*" element={<NotFound />} />
//       </Routes>
//     </Router>
//   );
// };
