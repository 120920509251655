import { TableHeadOrder } from "@alb/live-lib";
import { useEffect, useState } from "react";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IAccount, IApiResponse } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatOrderBy, arrayIsEmpty, ROWS_PER_PAGE } from "utils";
import { AccountsTable } from "./AccountsTable";

const Accounts = (props: { refetch: boolean }) => {
  const { refetch } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: accounts,
    loading,
    refetch: refetchAccounts
  } = useGetAll<IApiResponse<IAccount>>(ServiceApiUrl.accountURL, {
    page: page,
    items: ROWS_PER_PAGE,
    order_by: formatOrderBy(order, orderBy),
  });

  useEffect(() => {
    if (refetch) refetchAccounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  let accountsList = <NoData />;

  if (loading) {
    accountsList = <Loading />;
  }

  if (accounts && !arrayIsEmpty(accounts.data)) {
    accountsList = (
      <AccountsTable
        data={accounts}
        orderBy={orderBy}
        order={order}
        loading={loading}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        refetch={refetchAccounts}
      />
    );
  }

  return <>{accountsList}</>;
};

export default Accounts;
