export enum LogType{
	ERROR = "ERROR",
	INFO = "INFO"
}
export interface ILogType{
	ERROR: string;
	INFO: string
}

export interface ILogs {
	log_date: string;
	request: string;
	message: string;
	username: string;
	type: string;
}
