import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext } from "@alb/live-lib";
import EditIcon from "@mui/icons-material/Edit";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "@mui/material";
import { Box, Tab } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AdaptersTabList from "components/Adapters/AdaptersTabList";
import FilterSelect from "components/Utils/FilterSelect";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { TabPanelContainer } from "components/Utils/Tab/TabPanelContainer";
import { useGet } from "hooks/fetchData/useGet";
import usePrevLocation from "hooks/usePrevLocation";
import { IDevice, ISelectOption } from "interfaces";
import { RoutePaths } from "routes/routes";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ContainedTabs } from "styles/component-styles";
import { formatDate, formatRoutePath } from "utils";

import DevicesEdit from "./DevicesEdit";

const DevicesView = () => {
	let params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const prevLocation = usePrevLocation(RoutePaths.DEVICES);

	const { addHeader, addAction, action } = useContext(LayoutContext);

	const { data: device, loading, refetch } = useGet<IDevice>(
		ServiceApiUrl.deviceURL,
		params.id
	);

	const handleCloseEdit = () => {
		addAction(false);
	};
	const handleEditSuccess = () => {
		handleCloseEdit();

		// update list
		refetch();
	};
	const handleSelectedDevice = (option: ISelectOption | null) => {
		if (!option) return undefined;

		const path = formatRoutePath(location, params, {
			id: option.value,
		});

		navigate(path);
	};

	const header = {
		title: device?.name,
		backTo: (
			<BackToButton title={t("common.goBack")} onClick={() => navigate(prevLocation)} />
		),
		select: (device && <FilterSelect<IDevice> apiUrl={ServiceApiUrl.deviceURL} selectedValue={device?.id} onSelected={handleSelectedDevice} />),
		action: (
			<Button
				variant="contained"
				disableElevation
				startIcon={<EditIcon />}
				onClick={() => addAction(true)}
			>
				{t("devices.editDevice")}
			</Button>
		),
	};

	useEffect(() => {
		if (!device?.name) return;
		addHeader(header);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [device?.name]);

	let deviceView = <NoData />;
	let adapters = <NoData />;

	if (loading) {
		deviceView = <Loading />;
		adapters = <Loading />;
	}

	if (device) {
		deviceView = (
			<EntityInfo title={t("common.details")}>
				<EntityInfoItem
					fitContent={true}
					label={t("devices.form.nameLabel")}
					value={device.name}
				/>

				<EntityInfoItem
					label={t("devices.form.isActiveLabel")}
					value={device.is_active ? t("common.active") : t("common.inactive")}
				/>

				<EntityInfoItem
					label={t("devices.form.externalIDLabel")}
					value={device.external_id}
				/>
				<EntityInfoItem
					fitContent={true}
					label={t("devices.form.descriptionLabel")}
					value={device.description}
				/>
				<EntityInfoItem
					fitContent={true}
					label={t("devices.form.geolocationLabel")}
					value={device.geolocation.iconPosition.toString()}
				/>

				<EntityInfoItem
					label={t("common.createdAt")}
					value={formatDate(
						device.created_at,
						t("calendar.dateTimeFormatLocal")
					)}
				/>
				<EntityInfoItem
					label={t("common.updatedAt")}
					value={formatDate(
						device.updated_at,
						t("calendar.dateTimeFormatLocal")
					)}
				/>

				<EntityInfoItem
					fitContent={true}
					label={t("devices.form.extraParamsLabel")}
					value={JSON.stringify(device.extra_params)}
				/>
			</EntityInfo>
		);

		adapters = (
			<AdaptersTabList filters={{ 'device_id': device.id }} />
		);
	}

	let deviceEdit;
	if (action) {
		deviceEdit = (
			<DevicesEdit
				open={action}
				data={device}
				onClose={handleCloseEdit}
				onSuccess={handleEditSuccess}
			/>
		);
	}

	const [value, setValue] = React.useState("1");
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<>
			{deviceView}
			{deviceEdit}

			<Box mt={2}>
				<TabContext value={value}>
					<ContainedTabs indicatorColor="secondary" onChange={handleChange}>
						<Tab label={t("adapters.adapters")} value="1" />
					</ContainedTabs>

					<TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
						<TabPanelContainer>{adapters}</TabPanelContainer>
					</TabPanel>
				</TabContext>
			</Box>
		</>
	);
};

export default DevicesView;
