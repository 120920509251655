import { ConfirmDialog, TableHeadCell, TableHeadSort, useNotification } from "@alb/live-lib";
import { Table, TableBody, TableContainer } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useDelete } from "hooks/fetchData/useDelete";
import { ICategory } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, IResultTable, CustomTablePagination } from "utils";

import CategoriesEdit from "./CategoriesEdit";
import CategoriesTableRow from "./CategoriesTableRow";

export const CategoriesTable = (props: IResultTable) => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  const {
    data: categories,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    page,
    setPage,
    refetch,
    loading
  } = props;

  const [selectedCategory, setSelectedCategory] = useState<ICategory>();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const tableHeads: TableHeadCell[] = [
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "action_cell",
      label: "",
      notSortable: true,
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleEdit = (row: ICategory) => {
    // console.log("Edit: ", row);
    setSelectedCategory(row);
    setOpenEdit(true);
  };
  const handleDelete = (row: ICategory) => {
    // console.log("Delete:", row);
    setSelectedCategory(row);
    setOpenDeleteConfirm(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteConfirm(false);
    setSelectedCategory(undefined);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectedCategory(undefined);
  };
  const handleEditSuccess = () => {
    handleCloseEdit();

    // update list
    refetch && refetch();
  };

  const {
    loading: loadingDelete,
    refetch: deleteCategory,
  } = useDelete<ICategory>(ServiceApiUrl.categoryURL, selectedCategory?.id);

  // delete sucesso
  const handlerDeleteSuccess = () => {
    handleCloseDelete();
    addNotification({
      message: t("categories.categoryDeleted"),
      severity: "success",
    });

    // update list
    refetch && refetch();
  };

  const handleConfirmDelete = async () => {
    // console.log("confirm delete!");
    try {
      await deleteCategory();
      await handlerDeleteSuccess();
    } catch {}
  };

  let row;
  if (!arrayIsEmpty(categories.data)) {
    row = categories.data.map((category: ICategory) => (
      <CategoriesTableRow
        key={category.id}
        row={category}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    ));
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeadSort
            headCells={tableHeads}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            loading={loading}
          />
          <TableBody>{row}</TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        count={categories.totalCount}
        page={page}
				totalPages={categories.totalPages}
        onPageChange={handleChangePage}
      />

      {selectedCategory && (
        <>
          {openDeleteConfirm && (
            <ConfirmDialog
              open={openDeleteConfirm}
              title={t("categories.deleteConfirmTitle")}
              message={t("categories.deleteConfirmMessage")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              loading={loadingDelete}
              onCancel={handleCloseDelete}
              onConfirm={handleConfirmDelete}
							type="error"
            />
          )}

          {openEdit && (
            <CategoriesEdit
              open={openEdit}
              data={selectedCategory}
              onClose={handleCloseEdit}
              onSuccess={handleEditSuccess}
            />
          )}
        </>
      )}
    </>
  );
};
