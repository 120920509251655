import DevicesView from "components/Devices/DevicesView";

function DevicesViewPage() {
  return (
    <>
      <DevicesView />
    </>
  );
}

export default DevicesViewPage;
