// let addDays = (date: Date | string | number, days: number) => { format };

import { format } from "date-fns";
import { enGB, pt } from "date-fns/locale";

import { i18NEXTLNG_KEY } from "./localStorageKeys";
import { getLocalStorage } from "./localStore";

export function getLocale(){
  const storedLang = getLocalStorage<string>(i18NEXTLNG_KEY) || 'en';
  // const storedLang = localStorage.getItem(i18NEXTLNG_KEY) || 'en';

  const locales = {en: enGB, pt};
  const locale = locales[storedLang as keyof typeof locales] || enGB;

  return locale;
}

export function formatDate(date: Date | number | string | undefined, formatStr = "PP"): string {
  const dt = date ? new Date(date) : new Date();
  return format(dt, formatStr, {
    locale: getLocale()
  });
}

export function getLocaleMonth(month: number){
  return getLocale().localize?.month(month); // { width: 'abbreviated' }
}

export function nowDateTime(formatStr = "yyyy/MM/dd HH:mm"): string{
  const  dt = new Date();
  return format(dt, formatStr, {
    locale: getLocale()
  });
}


