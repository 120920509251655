import { addMethod, mixed, number, NumberSchema, object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 32,
    external_id: 100
  },
  min: {
    name: 1,
    external_id: 1
  },
};

function latitudeValidator(lat: number) {  
  return isFinite(lat) && Math.abs(lat) <= 90;  
}
function longitudeValidator(lng: number) {  
  return isFinite(lng) && Math.abs(lng) <= 180;  
}

// addMethod function
addMethod<NumberSchema>(
  number,
  "longitudeValidator",
  function (this, message: string) {
    return this.test("is-latitude", message, (number) => {
      if (number !== undefined) {
        return longitudeValidator(number);
      }
      return true;
    });
  }
);
addMethod<NumberSchema>(
  number,
  "latitudeValidator",
  function (this, message: string) {
    return this.test("is-latitude", message, (number) => {
      if (number !== undefined) {
        return latitudeValidator(number);
      }
      return true;
    });
  }
);

export const getDeviceFormSchema = (required: string) =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),
    external_id: string()
      .min(InputsLengths.min.external_id)
      .max(InputsLengths.max.external_id)
      .required(),

    // geolocation: string().required(),
    latitude: number().latitudeValidator().required(),
    longitude: number().longitudeValidator().required(),

    adapter_configurators: mixed().test(
      "required",
      required,
      (value) => Array.isArray(value) && value.length > 0
    ),

    description: string(),
  });
