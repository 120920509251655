import { useLocation } from 'react-router-dom'

const usePrevLocation = (defLoc: string) => {

	let location = useLocation();
	let from = location.state?.from?.pathname || defLoc || "-1";

	return from;
}

export default usePrevLocation;
