import { InputCheckbox, RequiredField, InputText } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ITenant } from "interfaces";

import { formDefaultValues } from "./formDefaultValues";
import { getTenantFormSchema } from "./validations";
import { RequiredFields } from "components/Utils/RequiredField";

const TenantsForm = (props: {
  formId: string;
  data?: ITenant;
  onFormSubmit: (payLoad: ITenant) => void;
	setFormIsValidStatus?: (status: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { formId, data, onFormSubmit, setFormIsValidStatus } = props;

   const defaultValues = formDefaultValues(data);

  const methodsForm = useForm<ITenant>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(getTenantFormSchema()),
  });

  const { control, handleSubmit, setValue } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<ITenant> = (payLoad: ITenant) => {
    onFormSubmit(payLoad);
  };

	useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [methodsForm.formState.isValid, setFormIsValidStatus]);


  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
							<RequiredField title={t("tenants.form.nameLabel")} />
              <InputText
                control={control}
                autoFocus={true}
                name="name"
                placeholder={t("tenants.form.namePlaceholder")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("tenants.form.isActiveLabel")}
              </Typography>
              <InputCheckbox
                control={control}
                name="is_active"
                setValue={setValue}
                checked={defaultValues.is_active}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
			<RequiredFields />
    </>
  );
};

export default TenantsForm;
