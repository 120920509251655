import { object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 32,
  },
  min: {
    name: 1,
  },
};

export const getPartnerFormSchema = () =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),    
    description: string(),    
  });
