import { mixed, object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 32,
  },
  min: {
    name: 1,
  },
};

export const getAccountFormSchema = (required: string) =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),

    adapter_configurators: mixed().test(
      "required",
      required,
      (value) => Array.isArray(value) && value.length > 0
    ), 

    description: string(),
  });
