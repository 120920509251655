import PartnersView from "components/Partners/PartnersView";

function PartnersViewPage() {
  return (
    <>
      <PartnersView />
    </>
  );
}

export default PartnersViewPage;
