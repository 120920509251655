import { LayoutContext } from "@alb/live-lib";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Streams from "components/Streams/Streams";
import StreamsAdd from "components/Streams/StreamsAdd";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function StreamsPage() {
  const { t } = useTranslation();

  const { addHeader, addAction, action } = useContext(LayoutContext);
  const [ added, setAdded ] = useState<boolean>(false);

  const header = {
    title: t("pages.streams.headerText"),
    action: (
      <Button
        variant="contained"
        disableElevation
        startIcon={<AddIcon />}
        onClick={() => addAction(true)}
      >
        {t("streams.addStream")}
      </Button>
    ),
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let streamAdd;
  if (action) {
    streamAdd = (
      <StreamsAdd
        open={action}
        onClose={() => addAction(false)}
        onSuccess={() => {
          addAction(false);
          setAdded(true);
        }}
      />
    );
  }

  return (
    <>
      <Streams refetch={added} />
      {streamAdd}
    </>
  );
}

export default StreamsPage;
