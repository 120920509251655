import useAxios from "axios-hooks";
import { AxiosRequestConfig } from "axios";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useEffect } from "react";
export function useDelete<T = any>(
  url: string,
  id?: string,
  othersConfig?: AxiosRequestConfig<any>
) {
  const { sendErrorMessage } = useErrorHandler();
  const verifiedUrl = id ? url + "/" + id : url;
  const config: AxiosRequestConfig = {
    ...othersConfig,
    url: verifiedUrl,
    method: "DELETE",
  };
  const [{ data, loading, error }, refetch, cancelRequest] = useAxios<T>(
    config,
    { manual: true }
  );

  useEffect(() => {
    if (error) {
      sendErrorMessage(error);
    }
  }, [error, sendErrorMessage]);

  return { data, loading, error, refetch, cancelRequest };
}
