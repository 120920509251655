import { TableHeadOrder } from "@alb/live-lib";
import { useEffect, useState } from "react";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { useGetAll } from "hooks/fetchData/useGetAll";
import { IAdapter, IApiResponse } from "interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty, formatOrderBy, ROWS_PER_PAGE } from "utils";

import { PartnersTable } from "./PartnersTable";

const Partners = (props: { refetch: boolean }) => {
  const { refetch } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: partners,
    loading,
    refetch: refetchPartners
  } = useGetAll<IApiResponse<IAdapter>>(ServiceApiUrl.partnerURL, {
    page: page,
    items: ROWS_PER_PAGE,
    order_by: formatOrderBy(order, orderBy),
  });

  useEffect(() => {
    if (refetch) refetchPartners();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  let partnersList = <NoData />;

  if (loading) {
    partnersList = <Loading />;
  }

  if (partners && !arrayIsEmpty(partners.data)) {
    partnersList = (
      <PartnersTable
        data={partners}
        orderBy={orderBy}
        order={order}
        loading={loading}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        refetch={refetchPartners}
      />
    );
  }

  return <>{partnersList}</>;
};

export default Partners;
